import React from 'react';
import Header from "@/components/Company/Header";
import MainBanner from '@/components/Common/new/MainBanner';
import OurServices from '@/components/Company/Home/OurServices';
import FeatureProduct from '@/components/Company/Home/FeatureProduct';
import Dealers from '@/components/Company/Home/Dealer';
import Footer from "@/components/Company/Footer";


import Cookies from "cookies";

//api
import dealer from "@/api/new/dealer";
import company from "@/api/new/company";
import Products from "@/api/new/product";

const Index = function ({
    dealers,
    main_banner,
    banner,
    sosmed,
    featured,
    products,
    icon,
    company,
    companyName,
    ...props
}) {
    const featuredProduct = products.map((data) => ({
        id : data.id,
        image: data.attributes.Image.data[0].attributes.url
    }));

    return (
        <>
            <Header company={companyName} icon={icon}/>
            <MainBanner className="mt-90" company={companyName} main_banner={main_banner} banner={banner} link_to={'business_partner'}/>
            {(companyName=='yamaha')?<OurServices />:''}
            <FeatureProduct company={companyName} featuredProduct={featuredProduct}/>
            <Dealers title={((companyName)?companyName[0].toUpperCase()+companyName.substring(1):'Yamaha')+' Dealers'} dealers={dealers}/>
            <Footer company={company} icon={icon} social={sosmed}/>
        </>
    )
}

export async function getServerSideProps(context) {
    var companyName = process.env.NEXT_PUBLIC_COMPANY;
    var props = {};
    try{
        var res = await company.companyByName(companyName);
        if (res["STATUS"] === 1) {
            props["featured"] = res["DATA"]["companies"].data[0].attributes.Featured.data;
            props["company"] = res["DATA"]["companies"].data[0].attributes;
            props["icon"] = res["DATA"]["companies"].data[0].attributes.Icon.data.attributes.url;
            props["main_banner"] = res["DATA"]["Main_Banner"].data;
            props["dealers"] = res["DATA"]["companies"].data[0].attributes.Dealers.data;
            props["banner"] = res["DATA"]["companies"].data[0].attributes.Banner.data;
            props["sosmed"] = res["DATA"]["companies"].data[0].attributes.Social_Medias.data;
        }

        var products = await Products.GetProduct(res["DATA"]["companies"].data[0].id,'Unit',8,1);
        if(products['STATUS']==1){
            props["products"]= products["DATA"].products.data;
        }
        props["companyName"]=companyName;
        
    }
    catch(e){
        console.log('error fetch company data',e);
    }
    
    return {
        props, // will be passed to the page component as props
    };
}

export default Index;